import { ISidebarUserProps } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

import { useSidebar } from './SidebarContext';

export default function NavItem({
    myProfileUrl,
    profilePictureUrl,
}: ISidebarUserProps): ReactElement {
    const { isCollapsed } = useSidebar();
    const classes = `image ${
        window.location.pathname === myProfileUrl ? 'active' : ''
    }`;

    const profileLink = (
        <a href={myProfileUrl}>
            {profilePictureUrl ? (
                <div className={classes}>
                    <div
                        className="inner"
                        style={{ backgroundImage: `url(${profilePictureUrl})` }}
                    ></div>
                </div>
            ) : (
                <div className={classes}>
                    <Icon name="user" />
                </div>
            )}
            <div
                className="text-muted d-none flex-grow-1 pl-3 overflow-hidden text-nowrap"
                style={{ display: `${isCollapsed ? 'none' : 'block'}` }}
            >
                My Profile
            </div>
        </a>
    );

    return (
        <li
            className="d-flex align-items-center p-2 position-fixed bg-white border-right border-top bottom-0 start-0 mb-0"
            key={myProfileUrl}
            style={{
                backgroundColor: 'white',
                borderColor: '#eeeeee',
                width: isCollapsed ? '70px' : '250px',
            }}
        >
            {isCollapsed ? (
                <Tooltip placement="right" title={'My Profile'}>
                    {profileLink}
                </Tooltip>
            ) : (
                profileLink
            )}
        </li>
    );
}
