import React, {
    ReactElement,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

interface ISidebarContextProps {
    isCollapsed: boolean;
    toggleSidebar: () => void;
}

interface IProps {
    children: ReactNode;
}

const SidebarContext = createContext<ISidebarContextProps | undefined>(
    undefined,
);

export function SidebarProvider({ children }: IProps): ReactElement {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
        const savedState = localStorage.getItem('sidebar-collapsed');
        return savedState === 'true';
    });

    useEffect(() => {
        localStorage.setItem('sidebar-collapsed', String(isCollapsed));
    }, [isCollapsed]);

    const toggleSidebar = (): void => setIsCollapsed(!isCollapsed);

    return (
        <SidebarContext.Provider value={{ isCollapsed, toggleSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
}

export const useSidebar = (): ISidebarContextProps => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
