import {
    ISidebarDiscordProps,
    ISidebarListBreakProps,
    ISidebarListItemProps,
    ISidebarUserProps,
} from 'holberton-school-intranet-api';
import React from 'react';
import { ReactElement } from 'react';

import Sidebar from '../sidebar/Sidebar';
import { SidebarProvider } from '../sidebar/SidebarContext';

import SidebarToggle from './SidebarToggle';

interface IProps {
    ehubHeaderSdkJsUrl: string;
    logoUrl: string;
    discord: ISidebarDiscordProps;
    user: ISidebarUserProps;
    listItems: (ISidebarListItemProps | ISidebarListBreakProps)[];
}

export default function Header({
    ehubHeaderSdkJsUrl,
    listItems,
    logoUrl,
    discord = null,
    user,
}: IProps): ReactElement {
    return (
        <SidebarProvider>
            <SidebarToggle
                ehubHeaderSdkJsUrl={ehubHeaderSdkJsUrl}
                logoUrl={logoUrl}
            />
            <Sidebar discord={discord} listItems={listItems} user={user} />
        </SidebarProvider>
    );
}
