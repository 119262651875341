import { ISidebarListItemProps } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

import { useSidebar } from './SidebarContext';

export default function NavItem({
    iconName,
    id,
    external,
    label,
    iconStyle,
    targetUrl,
}: ISidebarListItemProps): ReactElement {
    const { isCollapsed } = useSidebar();
    const isActive = window.location.pathname === targetUrl;
    const link = (
        <a href={targetUrl} target={external ? '_blank' : '_self'}>
            <div className={`icon ${isActive ? 'active' : ''}`}>
                <Icon name={iconName} style={iconStyle} />
            </div>
            <div
                className="pl-3 overflow-hidden text-nowrap"
                style={{ display: `${isCollapsed ? 'none' : 'block'}` }}
            >
                {label}
            </div>
        </a>
    );

    return (
        <li className="d-flex align-items-center" id={id} key={targetUrl}>
            {isCollapsed ? (
                <Tooltip placement="right" title={label}>
                    {link}
                </Tooltip>
            ) : (
                link
            )}
        </li>
    );
}
