import {
    ISidebarDiscordProps,
    ISidebarListBreakProps,
    ISidebarListItemProps,
    ISidebarUserProps,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import BreakItem from './BreakItem';
import DiscordItem from './DiscordItem';
import NavItem from './NavItem';
import ProfileItem from './ProfileItem';
import { useSidebar } from './SidebarContext';

interface IProps {
    discord: ISidebarDiscordProps;
    listItems: (ISidebarListItemProps | ISidebarListBreakProps)[];
    user: ISidebarUserProps;
}

export default function Sidebar({
    discord,
    listItems,
    user,
}: IProps): ReactElement {
    const { isCollapsed } = useSidebar();

    return (
        <>
            <div
                className="navigation sidebar hidden-xs h-full position-fixed border-right bottom-0 start-0 overflow-scroll"
                id="sidebar"
                style={{
                    borderColor: '#eeeeee',
                    top: '72px',
                    width: isCollapsed ? '70px' : '250px',
                }}
            >
                <ul className="px-2" style={{ marginBottom: '150px' }}>
                    <NavItem
                        iconName="home"
                        key="home"
                        label="Home"
                        targetUrl="/"
                    />
                    {listItems.map((item) =>
                        'targetUrl' in item ? (
                            <NavItem {...item} key={item.targetUrl} />
                        ) : (
                            <BreakItem
                                {...item}
                                key={`break-line-${item.label ||
                                    item.className}`}
                            />
                        ),
                    )}
                    {discord && <DiscordItem {...discord} key="discord" />}
                    <ProfileItem {...user} key="profile" />
                </ul>
            </div>
        </>
    );
}
